import React from 'react';

import { getHomepageDataServer, getHomepageMockupsServer } from '../actions/windowActions';
import { wrapper } from '../store/store';
import HomePage from '../containers/HomePage';
import initStaticProps from '../utils/pagesHelper';

const { LIVE_PREVIEW_MOCKUPS } = require('../utils/LivePreviewMockups/LivePreviewMockups');

export const getStaticProps = wrapper.getStaticProps(async ({ store }) => {
    await initStaticProps(store, [
        getHomepageDataServer(),
        getHomepageMockupsServer(LIVE_PREVIEW_MOCKUPS)
    ]);
    return {};
});

const Index = () => {
    return <HomePage />;
};

export default Index;
